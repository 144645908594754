var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "chatbox" }, [
    _c("div", { staticClass: "chat-headlines mx-auto" }, [
      _c("div", { staticStyle: { "max-width": "1150px" } }, [
        _vm.assignedAdmin
          ? _c("div", { staticClass: "mb-0 d-flex" }, [
              _c(
                "div",
                { staticClass: "text-right pr-3 d-flex align-items-center" },
                [
                  _c(
                    "b-avatar",
                    { staticStyle: { background: "#ece8f5", color: "black" } },
                    [_vm._v(" " + _vm._s(_vm.assignedAdmin.charAt(0)) + " ")]
                  ),
                ],
                1
              ),
              _c("div", [
                _c("b", [_vm._v(_vm._s(_vm.assignedAdmin))]),
                _c("br"),
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("Winona Patient Services"),
                ]),
              ]),
            ])
          : _c("div", { staticClass: "mb-0 d-flex align-items-center" }, [
              _c(
                "div",
                { staticClass: "text-right pr-3 d-flex align-items-center" },
                [
                  _c(
                    "b-avatar",
                    { staticStyle: { background: "#ece8f5", color: "black" } },
                    [_vm._v(" W ")]
                  ),
                ],
                1
              ),
              _vm._m(0),
            ]),
      ]),
    ]),
    _c("ul", { staticClass: "chat-holder list-unstyled mb-0" }, [
      _c(
        "li",
        { class: ["chat-box-container", _vm.imgUpload ? "uploading-img" : ""] },
        [
          _c(
            "div",
            {
              class: ["chat-box", _vm.mobileClass],
              attrs: { id: "chat-box" },
              on: {
                "&scroll": function ($event) {
                  return _vm.messageScroll.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "chat-holder" },
                [
                  _vm.isRecipientTyping
                    ? _c("BaseTypingIndicator", { staticClass: "mt-4" })
                    : _vm._e(),
                  _vm._l(_vm.groupMessage, function (messages, sentAt, index) {
                    return _c(
                      "div",
                      { key: sentAt, staticClass: "chat" },
                      [
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.formattedTimestamp(sentAt))),
                        ]),
                        _vm._l(messages, function (chat, i) {
                          return _c("div", { key: i }, [
                            chat.data.message || chat.data.attachments.length
                              ? _c(
                                  "div",
                                  {
                                    class: [
                                      chat.data.sender.id ==
                                      chat.data.patient.id
                                        ? "patient"
                                        : "doctor",
                                      "chat-bubble",
                                    ],
                                  },
                                  [
                                    chat.data.message
                                      ? _c(
                                          "div",
                                          {
                                            class: [
                                              chat.data.sender.id ==
                                              chat.data.patient.id
                                                ? "patient"
                                                : "doctor",
                                              "msg",
                                            ],
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "mb-0 d-letter-spacing",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(chat.data.message) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._l(
                                      _vm._chunk(
                                        chat.data.attachments,
                                        _vm.amountToGroupBy
                                      ),
                                      function (group, groupIndex) {
                                        return _c(
                                          "b-row",
                                          {
                                            key: groupIndex,
                                            class: [
                                              chat.data.sender.id ==
                                              chat.data.patient.id
                                                ? "patient"
                                                : "doctor",
                                              "msg",
                                              "p-0",
                                            ],
                                            staticStyle: {
                                              "background-color": "transparent",
                                            },
                                            attrs: { "no-gutters": "" },
                                          },
                                          [
                                            _vm._l(group, function (attach, i) {
                                              return [
                                                attach.type.includes("image") &&
                                                ![
                                                  "tiff",
                                                  "heic",
                                                  "svg",
                                                  "x-xbitmap",
                                                ].some((type) =>
                                                  attach.type.includes(type)
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { key: attach + i },
                                                      [
                                                        _c("b-img", {
                                                          attrs: {
                                                            thumbnail: "",
                                                            fluid: "",
                                                            src: attach.url,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : [
                                                      _c(
                                                        "a",
                                                        {
                                                          key: attach + i,
                                                          staticClass:
                                                            "p-2 d-block d-letter-spacing",
                                                          attrs: {
                                                            href: attach.url,
                                                            variant: "link",
                                                            download: "",
                                                            target: "_blank",
                                                          },
                                                        },
                                                        [
                                                          _c("b-icon", {
                                                            attrs: {
                                                              icon: "arrow-down-circle-fill",
                                                              variant:
                                                                "primary",
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                attach.filename
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                              ]
                                            }),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                class: [
                                  chat.data.sender.id != chat.data.patient.id
                                    ? "is-doctor"
                                    : "",
                                  "chat-img",
                                ],
                              },
                              [
                                chat.data.sender.id != chat.data.patient.id
                                  ? _c(
                                      "div",
                                      { staticClass: "img-holder" },
                                      [
                                        chat.data.sender.avatarUrl
                                          ? _c("img", {
                                              attrs: {
                                                src: chat.data.sender.avatarUrl,
                                                alt: "",
                                              },
                                            })
                                          : _c(
                                              "b-avatar",
                                              {
                                                staticStyle: {
                                                  background: "#ece8f5",
                                                  color: "black",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      chat.data.sender
                                                        .firstName &&
                                                        chat.data.sender.firstName.charAt(
                                                          0
                                                        )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "doctor-info" }, [
                                  chat.data.sender.id != chat.data.patient.id
                                    ? _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "mb-0 bold d-letter-spacing",
                                          staticStyle: { color: "#333333" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                chat.data.sender.firstName
                                              ) +
                                              " " +
                                              _vm._s(
                                                chat.data.sender.lastName
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "text-muted d-letter-spacing",
                                      staticStyle: { "font-size": "12px" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.moment
                                            .unix(chat.data.sentAt)
                                            .format("MMM DD, YYYY hh:mm A")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ])
                        }),
                        index === 0 && _vm.isUploading
                          ? _c(
                              "div",
                              { staticClass: "text-center" },
                              [_c("b-spinner", { attrs: { big: "" } })],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                ],
                2
              ),
            ]
          ),
          _c(
            "b-alert",
            {
              staticClass: "text-center",
              attrs: { show: _vm.alert.connection.show, variant: "danger" },
            },
            [
              _vm._v(" You are currently disconnected. Click "),
              _c(
                "a",
                {
                  staticClass: "text-light",
                  attrs: { href: "javascript:;" },
                  on: { click: _vm.reconnectApollo },
                },
                [_c("u", [_c("b", [_vm._v("reconnect")])])]
              ),
              _vm._v(" to continue messaging. "),
            ]
          ),
          _vm.conversation &&
          _vm.conversation.isbanned &&
          !_vm.isBanWithinOneMonth
            ? _c("div", { staticClass: "message text-center" }, [
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    " Your ability to message this doctor has been restricted due to a violation of our code of conduct. "
                  ),
                ]),
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    " Please seek medical assistance from another healthcare provider. "
                  ),
                ]),
              ])
            : _c(
                "div",
                { ref: "sendMessage", staticClass: "message" },
                [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.sendMessage()
                        },
                      },
                    },
                    [
                      _c("b-container", { staticClass: "px-0" }, [
                        _c("div", { staticClass: "message-holder" }, [
                          _c("div", { staticClass: "textarea-box" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newMessage,
                                  expression: "newMessage",
                                },
                              ],
                              ref: "typeMessage",
                              staticClass: "chat-textbox",
                              attrs: { placeholder: "Type a message" },
                              domProps: { value: _vm.newMessage },
                              on: {
                                blur: function ($event) {
                                  return _vm.hideLabel()
                                },
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.newMessage = $event.target.value
                                  },
                                  _vm.onType,
                                ],
                              },
                            }),
                            _c("div", [
                              _c(
                                "a",
                                {
                                  staticClass: "p-3 h-100",
                                  attrs: { href: "#" },
                                  on: { click: _vm.openUpload },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/paperclip_round.svg"),
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                              _c("input", {
                                ref: "fileInput",
                                staticStyle: { display: "none" },
                                attrs: { type: "file", multiple: "" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleFilesUpload()
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "button-holder d-flex align-items-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-none d-lg-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticStyle: {
                                        width: "50px",
                                        height: "46px",
                                      },
                                      attrs: {
                                        id: "send-button",
                                        pill: "",
                                        block: "",
                                        type: "submit",
                                        variant: "link",
                                        disabled:
                                          _vm.hasError ||
                                          _vm.isSending ||
                                          _vm.isMessageBodyEmpty,
                                      },
                                    },
                                    [
                                      _vm.isSending
                                        ? _c("b-spinner", {
                                            attrs: { small: "" },
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/send.svg"),
                                              alt: "",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_vm.isMessageBodyEmpty && !_vm.isSending
                                ? _c(
                                    "b-tooltip",
                                    { attrs: { target: "send-button" } },
                                    [_vm._v(" Send message ")]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "d-lg-none d-flex" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        id: "send-button-1",
                                        pill: "",
                                        block: "",
                                        type: "submit",
                                        variant: "link",
                                        disabled:
                                          _vm.hasError ||
                                          _vm.isSending ||
                                          _vm.isMessageBodyEmpty,
                                      },
                                    },
                                    [
                                      _vm.isSending
                                        ? _c("b-spinner", {
                                            attrs: { small: "" },
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/send.svg"),
                                              alt: "",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_vm.isMessageBodyEmpty && !_vm.isSending
                                ? _c(
                                    "b-tooltip",
                                    { attrs: { target: "send-button-1" } },
                                    [_vm._v(" Send message ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm.imgUpload
                    ? _c(
                        "b-container",
                        { ref: "uploadImg", staticClass: "pre-upload" },
                        _vm._l(_vm.attachments, function (attachment, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "img-holder" },
                            [
                              _c("b-icon", {
                                staticClass: "close-btn",
                                attrs: { icon: "x-circle-fill" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFile(index)
                                  },
                                },
                              }),
                              _vm.viewImage(attachment)
                                ? _c("img", {
                                    attrs: {
                                      src: attachment.url,
                                      alt: attachment.name,
                                    },
                                  })
                                : !_vm.viewImage(attachment)
                                ? _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/pdf-logo.png"),
                                      alt: attachment.name,
                                    },
                                  })
                                : _c("div", [
                                    !attachment.type.includes("image") ||
                                    attachment.type.includes("heic") ||
                                    attachment.type.includes("tiff") ||
                                    attachment.type.includes("x-xbitmap")
                                      ? _c(
                                          "svg",
                                          {
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              width: "32",
                                              height: "32",
                                              viewBox: "0 0 24 24",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                fill: "currentColor",
                                                d: "M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59H6zm7 6V3.5L18.5 9H14c-.55 0-1-.45-1-1z",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "text-muted" }, [
        _vm._v("Winona Patient Services"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }