import { BaseService } from "./base.service";
import { ErrorWrapper, ResponseWrapper } from "./util";

export class AdminChatService extends BaseService {
  static async getMessages(patientid, nextpage) {
    try {
      const response = await this.request({ auth: true }, "v3").get(
        `/admin/patients/${patientid}/messages?limit=50${
          nextpage ? `&cursor=${nextpage}` : ""
        }`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getAssignedAdmin(patientid) {
    try {
      const response = await this.request({ auth: true }, "v3").get(
        `/admin-patient-chat/conversations/${patientid}`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
