var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "typing-indicator" },
    _vm._l(3, function (i) {
      return _c("div", { key: i, staticClass: "dot", class: "dot" + i })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }